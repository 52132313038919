import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { MiddlewareService } from './../../services/middleware.service';
import { Injectable } from '@angular/core';
import * as BillingActions from './billing.actions';
import * as SessionSelectors from './../session/session.selectors';
import { of } from 'rxjs';
import { CmContract } from './../../models/cm-contract.model';

@Injectable()
export class BillingEffects {
  constructor(private readonly action$: Actions, private readonly middleware: MiddlewareService, private readonly http: HttpClient, private readonly store: Store) { }

  loadAvailablePaymentMethods$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.loadAvailablePaymentMethods),
      concatMap(action =>
        this.middleware.get('/management/v2/contracts/current/payment-methods-whitelist').pipe(
          map((result: any) => BillingActions.loadAvailablePaymentMethodsSuccess({ data: result.data.map((entry: any) => entry.attributes) })),
          catchError(error => of(BillingActions.loadAvailablePaymentMethodsFailure({ error })))
        )
      )
    )
  );

  loadActivatedPaymentMethods$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        BillingActions.loadActivatedPaymentMethods,
        BillingActions.addPaymentMethodSuccess,
        BillingActions.removePaymentMethodSuccess,
        BillingActions.setPaymentMethodAsDefaultSuccess,
        BillingActions.updatePaymentMethodSuccess,
        BillingActions.validateSepaSuccess
      ),
      concatMap(action =>
        this.middleware.get('/management/v2/contracts/current/payment-methods', undefined, [401]).pipe(
          map((result: any) => BillingActions.loadActivatedPaymentMethodsSuccess({ data: result.data })),
          catchError(error => of(BillingActions.loadActivatedPaymentMethodsFailure({ error })))
        )
      )
    )
  );

  addPaymentMethod$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.addPaymentMethod),
      concatMap(action =>
        this.middleware.post(`/management/v2/contracts/current/payment-methods`, { name: action.name, payment_method_details: action.payment_method_details }).pipe(
          map((result: any) => BillingActions.addPaymentMethodSuccess({ data: result.data })),
          catchError(error => of(BillingActions.addPaymentMethodFailure({ error })))
        )
      )
    )
  );

  removePaymentMethod$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.removePaymentMethod),
      concatMap(action =>
        this.middleware.delete(`/management/v2/contracts/current/payment-methods/${action.id}`).pipe(
          map((result: any) => BillingActions.removePaymentMethodSuccess({ data: result.data })),
          catchError(error => of(BillingActions.removePaymentMethodFailure({ error })))
        )
      )
    )
  );

  updatePaymentMethod$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.updatePaymentMethod),
      concatMap(action =>
        this.middleware.patch(`/management/v2/contracts/current/payment-methods/${action.id}`, { name: action.name, payment_method_details: action.payment_method_details }).pipe(
          map((result: any) => BillingActions.updatePaymentMethodSuccess({ data: result.data })),
          catchError(error => of(BillingActions.updatePaymentMethodFailure({ error })))
        )
      )
    )
  );

  setPaymentMethodAsDefault$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.setPaymentMethodAsDefault),
      concatMap(action =>
        this.middleware.patch(`/management/v2/contracts/current/payment-methods/${action.id}/default`).pipe(
          map((result: any) => BillingActions.setPaymentMethodAsDefaultSuccess({ data: result.data })),
          catchError(error => of(BillingActions.setPaymentMethodAsDefaultFailure({ error })))
        )
      )
    )
  );

  loadBillingAddress$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.loadBillingAddress, BillingActions.addBillingAddressSuccess),
      concatMap(action =>
        this.middleware.get('/management/v2/contracts/current/billingAddress').pipe(
          map((result: any) => BillingActions.loadBillingAddressSuccess({ data: result.data.attributes })),
          catchError(error => of(BillingActions.loadBillingAddressFailure({ error })))
        )
      )
    )
  );

  addBillingAddress$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.addBillingAddress),
      concatMap(action =>
        this.middleware.patch('/management/v2/contracts/current/billingAddress', action.data).pipe(
          map((result: any) => BillingActions.addBillingAddressSuccess({ data: result.data })),
          catchError(error => of(BillingActions.addBillingAddressFailure({ error })))
        )
      )
    )
  );

  loadContract$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.loadContract, BillingActions.saveBillingMetadataSuccess, BillingActions.validateSepaSuccess, BillingActions.addPaymentMethodSuccess),
      concatMap(() =>
        this.middleware.get('/management/v2/contracts/current').pipe(
          map((result: { data: CmContract }) => BillingActions.loadContractSuccess({ data: result.data })),
          catchError(error => of(BillingActions.loadContractFailure({ error })))
        )
      )
    )
  );

  saveontract$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.saveContract),
      concatMap(action =>
        this.middleware.patch('/management/v2/contracts/current', action.data).pipe(
          map((result: any) => BillingActions.saveContractSuccess({ data: result.data })),
          catchError(error => of(BillingActions.saveContractFailure({ error })))
        )
      )
    )
  );

  saveBillingMetadata$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.saveBillingMetadata),
      concatMap(action =>
        this.middleware.patch('/management/v2/contracts/current/billingMetadata', action.data).pipe(
          map((result: any) => BillingActions.saveBillingMetadataSuccess({ data: result.data })),
          catchError(error => of(BillingActions.saveBillingMetadataFailure({ error })))
        )
      )
    )
  );

  validateSepa$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.validateSepa),
      concatMap(action =>
        this.middleware.patch(`/management/v2/contracts/current/payment-methods/${action.paymentMethodUuid}/verify`, { verify_pin: action.verify_pin }, [400]).pipe(
          map((result: any) => BillingActions.validateSepaSuccess()),
          catchError(error => of(BillingActions.validateSepaFailure({ error })))
        )
      )
    )
  );

  getInit$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.getInit),
      concatMap(() =>
        this.http.get('/v2/init').pipe(
          map((result: any) => BillingActions.getInitSuccess({ data: result.body })),
          catchError(error => of(BillingActions.getInitFailure({ error })))
        )
      )
    )
  );

  setAgbState$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActions.setAgbState),
      withLatestFrom(this.store.select(SessionSelectors.getSessionData)),
      concatMap(([action, session]) =>
        this.http.patch('/account/setagb', { object_uuid: session.contract_uuid }).pipe(
          map((result: any) => BillingActions.setAgbStateSuccess()),
          catchError(error => of(BillingActions.setAgbStateFailure({ error })))
        )
      )
    )
  );
}
