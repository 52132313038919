import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as SessionActions from './../session/session.actions';
import * as ProjectsActions from './../project/project.actions';
import * as UserActions from './user.actions';
import { MiddlewareService } from './../../services/middleware.service';
import { globalFailure, noopAction } from '../common.actions';

import * as ContractSelectors from './../contract/contract.selectors';

import { Store } from '@ngrx/store';
import { getCurrentUserUuid } from '../session/session.selectors';
import { getCurrentUser, getStoredAutoLogin } from './user.selectors';
import { finalizeContractSuccess, patchContractSuccess } from '../contract/contract.actions';
import { TypedAction } from '@ngrx/store/src/models';
import * as _ from 'lodash';
import { UserRoleService } from './../../services/userrole.service';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private middleware: MiddlewareService, private store: Store, private userRoleService: UserRoleService) { }

  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUsers, UserActions.loadUsersNext, UserActions.deleteUserSuccess),
      withLatestFrom(this.store.select(ContractSelectors.getCurrentContractUuid)),
      concatMap(([action, contract_uuid]) =>
        this.middleware.get('/v3/contracts/' + contract_uuid + '/users').pipe(
          map((result: any) => {
            return UserActions.loadUsersSuccess({
              data: result.body.users
            });
          }),
          catchError(error => of(UserActions.loadUsersFailure({ error }), globalFailure({ error })))
        )
      )
    );
  });

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUser, UserActions.patchUserSuccess, UserActions.patchRoleSuccess),
      withLatestFrom(this.store.select(getCurrentUserUuid), this.store.select(ContractSelectors.getCurrentContractUuid)),
      concatMap(([action, user_uuid, contract_uuid]) => {
        // Allow to load single User Data for yourself or if you are the owner!
        if (action.uuid == user_uuid) {
          return this.middleware.get('/v1/user/' + action.uuid).pipe(
            map((result: any) => {
              return UserActions.loadUserSuccess({
                uuid: action.uuid,
                data: result.body.user
              });
            }),
            catchError(error => of(UserActions.loadUserFailure({ uuid: action.uuid, error }), globalFailure({ error })))
          );

          // If we are not allowed to load Single User Details, we just load the "normal one"
        } else {
          return this.middleware.get('/v3/contracts/' + contract_uuid + '/users').pipe(
            map((result: any) => {
              return UserActions.loadUsersSuccess({
                data: result.body.users,
                uuid: action.uuid
              });
            }),
            catchError(error => of(UserActions.loadUsersFailure({ error, uuid: action.uuid }), globalFailure({ error })))
          );
        }
      })
    );
  });

  loadCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserActions.loadCurrentUser,
        SessionActions.loginSuccess,
        SessionActions.loadSessionSuccess,
        UserActions.patchCurrentUserSuccess,
        ProjectsActions.patchProjectSuccess,
        finalizeContractSuccess
      ),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        if (user_uuid) {
          return of(UserActions.loadUser({ uuid: user_uuid as unknown as string }));
        } else {
          return of(noopAction());
        }
      })
    );
  });

  patchUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.patchUser),
      concatMap(action =>
        this.middleware.patch('/v3/users/' + action.uuid, action.data).pipe(
          map((result: any) => {
            return UserActions.patchUserSuccess({
              uuid: action.uuid
            });
          }),
          catchError(error => of(UserActions.patchUserFailure({ error, uuid: action.uuid }), globalFailure({ error })))
        )
      )
    );
  });

  patchCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.patchCurrentUser),
      concatMap(action =>
        this.middleware.patch('/v3/users/current', action.data).pipe(
          map((result: any) => {
            return UserActions.patchCurrentUserSuccess({
              request_id: result
            });
          }),
          catchError(error => of(UserActions.patchCurrentUserFailure({ error }), globalFailure({ error })))
        )
      )
    );
  });

  resend$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.resend),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.get('/v3/user/' + user_uuid + '/resend').pipe(
          map((result: any) => {
            return UserActions.resendSuccess();
          }),
          catchError(error => of(UserActions.resendFailure({ error }), globalFailure({ error })))
        );
      })
    );
  });

  /********************************
   *          Password    *
   ********************************/

  loadPasswords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadPasswords, UserActions.patchPasswordsSuccess),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.get('/v3/users/' + user_uuid + '/auths/passwords').pipe(
          map((result: any) => {
            return UserActions.loadPasswordsSuccess({ data: result.body.passwords, uuid: user_uuid });
          }),
          catchError(error => of(UserActions.loadPasswordsFailure({ error, uuid: user_uuid })))
        );
      })
    );
  });

  postPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.postPassword),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.post('/v3/users/' + user_uuid + '/auths/passwords', action.data).pipe(
          map((result: any) => {
            return UserActions.postPasswordSuccess({ request_id: result });
          }),
          catchError(error => of(UserActions.postPasswordFailure({ error })))
        );
      })
    );
  });

  patchPasswords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.patchPasswords),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.patch('/v3/users/' + user_uuid + '/auths/passwords/' + action.uuid, action.data).pipe(
          map((result: any) => {
            return UserActions.patchPasswordsSuccess({ request_id: result });
          }),
          catchError(error => of(UserActions.patchPasswordsFailure({ error })))
        );
      })
    );
  });

  /********************************
   *          OTPTOKENS           *
   ********************************/

  loadOtps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadOtps, UserActions.validateOTPSuccess, UserActions.deleteOTPSuccess),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.get('/v3/users/' + user_uuid + '/auths/otptokens').pipe(
          map((result: any) => {
            return UserActions.loadOtpsSuccess({ data: result.body.otptokens, uuid: user_uuid });
          }),
          catchError(error => of(UserActions.loadOtpsFailure({ error, uuid: user_uuid })))
        );
      })
    );
  });

  createOtps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.createOTP),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.post('/v3/users/' + user_uuid + '/auths/otptokens', action.data).pipe(
          map((result: any) => {
            return UserActions.createOTPSuccess({ data: { ...result.body, name: action.data.name, create_time: new Date().toString() } });
          }),
          catchError(error => of(UserActions.createOTPFailure({ error })))
        );
      })
    );
  });

  validateOtps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.validateOTP),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.post('/v3/users/' + user_uuid + '/auths/otptokens/' + action.token_uuid + '/validate', action.data, [403, 400]).pipe(
          map((result: any) => {
            return UserActions.validateOTPSuccess({ token_uuid: action.token_uuid });
          }),
          catchError(error => of(UserActions.validateOTPFailure({ token_uuid: action.token_uuid })))
        );
      })
    );
  });

  deleteOtp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteOTP),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.delete('/v3/users/' + user_uuid + '/auths/otptokens/' + action.token_uuid).pipe(
          map(() => {
            return UserActions.deleteOTPSuccess({ token_uuid: action.token_uuid });
          }),
          catchError(error => of(UserActions.deleteOTPailure({ token_uuid: action.token_uuid, error })))
        );
      })
    );
  });

  /********************************
   *          SAML    *
   ********************************/

  loadSamls$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadSamls, UserActions.updateSamlSuccess),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.get('/v3/users/' + user_uuid + '/auths/samls').pipe(
          map((result: any) => {
            return UserActions.loadSamlsSuccess({ data: result.body.user_relations });
          }),
          catchError(error => of(UserActions.loadSamlsFailure({ error })))
        );
      })
    );
  });

  deactivateSaml$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deactivateSaml),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.delete('/v3/users/' + user_uuid + '/auths/samls/' + action.saml_uuid).pipe(
          map((result: any) => {
            return UserActions.updateSamlSuccess({ saml_uuid: action.saml_uuid });
          }),
          catchError(error => of(UserActions.updateSamlFailure({ error, saml_uuid: action.saml_uuid })))
        );
      })
    );
  });

  activateSaml$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.activateSaml),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.post('/v3/users/' + user_uuid + '/auths/samls', action.data).pipe(
          map((result: any) => {
            return UserActions.updateSamlSuccess({ saml_uuid: action.data.saml_uuid });
          }),
          catchError(error => of(UserActions.updateSamlFailure({ error, saml_uuid: action.data.saml_uuid })))
        );
      })
    );
  });

  /********************************
   *          Invites    *
   ********************************/

  loadInvites$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadInvites, UserActions.createInviteTokenSuccess, UserActions.deleteInviteTokenSuccess),
      withLatestFrom(this.store.select(ContractSelectors.getCurrentContractUuid)),
      concatMap(([action, contract_uuid]) => {
        return this.middleware.get('/v3/contracts/' + contract_uuid + '/invitetokens').pipe(
          map((result: any) => {
            return UserActions.loadInvitesSuccess({ data: result.body.invitetokens });
          }),
          catchError(error => of(UserActions.loadInvitesFailure({ error })))
        );
      })
    );
  });

  createInviteToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.createInviteToken),
      withLatestFrom(this.store.select(ContractSelectors.getCurrentContractUuid)),
      concatMap(([action, contract_uuid]) => {
        return this.middleware.post('/v3/contracts/' + contract_uuid + '/invitetokens', action.data).pipe(
          map((result: any) => {
            return UserActions.createInviteTokenSuccess({ data: { ...result.body, name: action.data.name, create_time: new Date().toString() } });
          }),
          catchError(error => of(UserActions.createInviteTokenFailure({ error })))
        );
      })
    );
  });

  deleteInviteToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteInviteToken),
      withLatestFrom(this.store.select(ContractSelectors.getCurrentContractUuid)),
      concatMap(([action, contract_uuid]) => {
        return this.middleware.delete('/v3/contracts/' + contract_uuid + '/invitetokens/' + action.invite_uuid).pipe(
          map(() => {
            return UserActions.deleteInviteTokenSuccess({ invite_uuid: action.invite_uuid });
          }),
          catchError(error => of(UserActions.deleteInviteTokenFailure({ invite_uuid: action.invite_uuid, error })))
        );
      })
    );
  });

  /********************************
   *          ROLE    *
   ********************************/
  patchRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.patchRole),
      withLatestFrom(this.store.select(ContractSelectors.getCurrentContractUuid)),
      concatMap(([action, contract_uuid]) => {
        return this.middleware.patch('/v3/contracts/' + contract_uuid + '/users/' + action.uuid, { role: action.data.user_role }).pipe(
          map((result: any) => {
            return UserActions.patchRoleSuccess({ uuid: action.uuid });
          }),
          catchError(error => of(UserActions.patchRoleFailure({ uuid: action.uuid, error })))
        );
      })
    );
  });

  deleteUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteUser),
      withLatestFrom(this.store.select(ContractSelectors.getCurrentContractUuid)),
      concatMap(([action, contract_uuid]) => {
        return this.middleware.delete('/v3/contracts/' + contract_uuid + '/users/' + action.uuid).pipe(
          map(() => {
            return UserActions.deleteUserSuccess({ uuid: action.uuid });
          }),
          catchError(error => of(UserActions.deleteUserFailure({ uuid: action.uuid, error })))
        );
      })
    );
  });

  onRelationsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchContractSuccess),
      withLatestFrom(this.store.select(getCurrentUser)),
      mergeMap(([action, user]) => {
        const actions: TypedAction<string>[] = [];

        // go thru each server in the store, check if we have the affected object, and reload it
        const relationKey = 'contracts';

        if (
          _.find(_.get(user, ['relations', relationKey], []), {
            contract_uuid: action.uuid
          }) !== undefined
        ) {
          actions.push(UserActions.loadCurrentUser());
        }

        return actions;
      })
    )
  );

  /********************************
   *          AUTO Login    *
   ********************************/

  getAutoLoginSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getAutoLoginSettings, UserActions.loadCurrentUser, SessionActions.loginSuccess, SessionActions.loadSessionSuccess),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.get('/users/' + user_uuid + '/auto-login-settings').pipe(
          map((result: any) => {
            return UserActions.getAutoLoginSettingsSuccess({ data: result });
          }),
          catchError(error => of(UserActions.getAutoLoginSettingsFailure({ error })))
        );
      })
    );
  });

  patchAutoLoginSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.patchAutoLoginSettings),
      withLatestFrom(this.store.select(getCurrentUserUuid)),
      concatMap(([action, user_uuid]) => {
        return this.middleware.patch('/users/' + user_uuid + '/auto-login-settings', action.data).pipe(
          map((result: any) => {
            return UserActions.patchAutoLoginSettingsSuccess({ data: result });
          }),
          catchError(error => of(UserActions.patchAutoLoginSettingsFailure({ error })))
        );
      })
    );
  });

  patchAutoLoginSettingsAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.loadSessionSuccess),
      withLatestFrom(this.store.select(getStoredAutoLogin), this.store.select(getCurrentUserUuid)),
      filter(([action, patch_uuid, user_uuid]) => {
        return patch_uuid != null;
      }),
      concatMap(([action, patch_uuid, user_uuid]) => {
        return this.middleware.patch('/users/' + user_uuid + '/auto-login-settings', { selected_auto_login_option_object_uuid: patch_uuid, is_auto_login_active: true }).pipe(
          map((result: any) => {
            return UserActions.patchAutoLoginSettingsSuccess({ data: result });
          }),
          catchError(error => of(UserActions.patchAutoLoginSettingsFailure({ error })))
        );
      })
    );
  });
}
