import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ContractActions from './contract.actions';
import * as SessionActions from './../session/session.actions';
import { MiddlewareService } from './../../services/middleware.service';
import { globalFailure, noopAction } from '../common.actions';
import { createProjectSuccess, deleteProjectSuccess, patchProjectSuccess } from '../project/project.actions';
import { getCurrentContract } from './contract.selectors';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { TypedAction } from '@ngrx/store/src/models';
import { getCurrentContractUuid } from '../session/session.selectors';
import { AuthService } from '../../../auth/auth.service';

@Injectable()
export class ContractEffects {
  constructor(private actions$: Actions, private middleware: MiddlewareService, private readonly store: Store, private authService: AuthService) { }

  // ngrxOnInitEffects() {
  //   return ContractActions.loadCurrentContract();
  // }

  loadContract$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ContractActions.patchContractSuccess,
      ),
      concatMap(action =>
        this.middleware.get('/v3/contracts/' + action.uuid).pipe(
          map((result: any) => {
            return ContractActions.loadCurrentContractSuccess({
              data: result.body.contract
            });
          }),
          catchError(error => of(ContractActions.loadCurrentContractFailure({ error }), globalFailure({ error })))
        )
      )
    );
  });

  loadCurrentContract$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ContractActions.loadCurrentContract,
        SessionActions.loginSuccess,
        SessionActions.loadSessionSuccess,
        ContractActions.finalizeContractSuccess
      ),
      withLatestFrom(this.store.select(getCurrentContractUuid)),
      concatMap(([action, contractUuid]) => {
        if (contractUuid) {
          return this.middleware.get('/v3/contracts/' + contractUuid).pipe(
            map((result: any) => {
              return ContractActions.loadCurrentContractSuccess({
                data: result.body.contract
              });
            }),
            catchError(error => {
              // If there is no current Contract! Logout
              if (error.status !== 0) {
                this.authService.logout().pipe(take(1)).subscribe();
              }
              return of(ContractActions.loadCurrentContractFailure({ error }), globalFailure({ error }))
            }
            )
          );
        } else {
          return of(noopAction());
        }
      })
    );
  });

  patchContract$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContractActions.patchContract),
      concatMap(action =>
        this.middleware.patch('/v3/contracts/' + action.uuid, action.data).pipe(
          map((result: any) => {
            return ContractActions.patchContractSuccess({
              uuid: action.uuid
            });
          }),
          catchError(error => of(ContractActions.patchContractFailure({ error, uuid: action.uuid }), globalFailure({ error })))
        )
      )
    );
  });

  finalizeContract$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContractActions.finalizeContract),
      concatMap(action =>
        this.middleware.patch('/v1/contract/finalize', {
          first_name: action.first_name,
          last_name: action.last_name,
          phone_number: action.phone_number,
          contract_name: action.contract_name,
          email: action.email
        }).pipe(
          map((result: any) => {
            return ContractActions.finalizeContractSuccess();
          }),
          catchError(error => of(ContractActions.finalizeContractFailure({ error }), globalFailure({ error })))
        )
      )
    );
  });

  onRelationsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        createProjectSuccess,
        patchProjectSuccess,
        deleteProjectSuccess
      ),
      withLatestFrom(
        this.store.select(getCurrentContract)
      ),
      mergeMap(([action, contract]) => {
        const actions: TypedAction<string>[] = [];


        // go thru each server in the store, check if we have the affected object, and reload it
        const relationKey = 'projects';

        if (_.find(
          _.get(contract, ['relations', relationKey], []),
          {
            object_uuid: action.uuid
          }
        ) !== undefined) {

          actions.push(ContractActions.loadCurrentContract());
        }

        return actions;
      })
    )
  );
}
